import { FT_AUTOCOMPLETE, FT_LIST, FT_SINGLE } from '../FilterTypes';
import { CAT_TYPES } from 'App/utils/Collections';
import { loadModules, searchProductTypes } from './loaders';

const groups = {
  general: {
    name: 'General',
    groupOrderIndex: 0,
  },
  parent: {
    name: 'Parent',
    groupOrderIndex: 1,
  },
};

export default {
  category: CAT_TYPES,
  filters: [
    {
      id: 'id',
      label: 'ID Number',
      type: FT_SINGLE,
      group: groups.general,
      hint: '',
    },
    {
      id: 'modules',
      label: 'Module',
      type: FT_LIST,
      group: groups.general,
      getListItems: loadModules,
    },
    {
      id: 'parent',
      propertyName: 'parent.id',
      label: 'Parent',
      type: FT_AUTOCOMPLETE,
      group: groups.parent,
      fieldConf: {
        searcher: async (term) => {
          return searchProductTypes(term);
        },
        items: async () => [],
        attrs: {
          itemText: 'name',
          itemValue: 'id',
        },
      },
      useNASearch: true,
      inverseNA: true,
      naProp: 'exists[parent]',
    },
  ],
};
