import { ApiError } from 'App/Dataman';
import moment from 'moment';
import { diff } from 'deep-object-diff';

export function formatDate(datetime, showTime = true, options = {}) {
  const _datetime = new Date(datetime);
  const _options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...options,
  };
  let date = _datetime.toLocaleDateString('en-US', _options);
  if (showTime) {
    const time = _datetime.toLocaleTimeString('en-US', { timeStyle: 'short' });
    date = `${date}, ${time}`;
  }
  return date;
}

export function upperFirst(str) {
  let _str;
  if (typeof str !== 'string' && !!str && typeof str.toString === 'function') {
    _str = str.toString();
  } else {
    _str = str;
  }
  if (typeof _str !== 'string') {
    return str;
  }

  return _str.replace(/^./, (match) => match.toUpperCase());
}

export function sortBy(prop, dir = 'asc', normalizer = null) {
  return (itemA, itemB) => {
    const valA = typeof normalizer === 'function' ? normalizer(itemA[prop]) : itemA[prop];
    const valB = typeof normalizer === 'function' ? normalizer(itemB[prop]) : itemB[prop];
    if (dir === 'asc') {
      return valA > valB ? 1 : -1;
    } else if (dir === 'desc') {
      return valA > valB ? -1 : 1;
    }

    return 0;
  };
}

export function getSorterByDates(dir = 'asc', prop = null) {
  return (a, b) => {
    const da = new Date(prop ? a[prop] : a);
    const db = new Date(prop ? b[prop] : b);
    const dif = da - db;
    if (dif === 0) {
      return 0;
    }
    return dir === 'asc' ? dif : -dif;
  };
}

export function zeroPad(str, n = 2) {
  return ('' + str).padStart(n, '0');
}

export function baseName(path, delimiter = '/') {
  const [basename] = path.split(delimiter).reverse();

  return basename;
}

export function rootName(path, delimiter = '/') {
  const [root] = path.split(delimiter).filter(Boolean);

  return root;
}

export function decodeJWT(token) {
  if (!token) {
    throw new Error('Empty token');
  }
  const [header, payload, signature] = token.split('.');
  return {
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload)),
    signature,
  };
}

export function sortStringDates(strDate, anotherStrDate) {
  return new Date(strDate) - new Date(anotherStrDate);
}

export function normalizeImgUrl(url = '', protocol = null) {
  if (!url || typeof url !== 'string') {
    return null;
  }
  if (/^(\s*https?:)?\/\//i.test(url)) {
    return url;
  } else if (url.match(/^\/api\/product_images\/\d+$/)) {
    return null;
  } else {
    return `${protocol || window.location.protocol}//${url}`;
  }
}

export function flipObject(obj = {}) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
}

export function saveBinaryData(data, filename, type = 'octet/stream') {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.target = '_blank';
  a.click();
  URL.revokeObjectURL(url);
}

export function arrDiff(a, b) {
  return b.filter((el) => !a.includes(el));
}

export function arrFullDiff(a, b) {
  return [...a.filter((el) => !b.includes(el)), ...b.filter((el) => !a.includes(el))];
}

export function persist(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function restore(key, defValue = undefined) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }

  return defValue;
}

/**
 * Returns debouncing wrapper
 * @param {number} delay
 * @returns {(function(*=): void)|*}
 */
export function getDebouncer(delay = 100) {
  let timeout;
  return (callback, cancelCallback = null) => {
    if (timeout) {
      if (typeof cancelCallback === 'function') {
        cancelCallback();
      }
      clearTimeout(timeout);
    }
    timeout = setTimeout(callback, delay);
  };
}

export function getIterator(start = 0, step = 1) {
  let i = start;
  return () => {
    const next = i;
    i += step;
    return next;
  };
}

export const MIME_BMP = 'image/bmp';
export const MIME_PNG = 'image/png';
export const MIME_GIF = 'image/gif';
export const MIME_JPG = 'image/jpeg';
export const MIME_ICO = 'image/x-icon';
export const MIME_WEBP = 'image/webp';
export const MIME_SVG = 'image/svg+xml';
export const MIME_PDF = 'application/pdf';

const MIME_SIGNATURES_MAP_2 = {
  '424d': MIME_BMP,
};

const MIME_SIGNATURES_MAP_4 = {
  ffd8ffe0: MIME_JPG,
  ffd8ffe1: MIME_JPG,
  ffd8ffe2: MIME_JPG,
  ffd8ffe3: MIME_JPG,
  ffd8ffe8: MIME_JPG,
  '00000100': MIME_ICO,
  '00000200': MIME_ICO,
};

const MIME_SIGNATURES_MAP_6 = {
  474946383761: MIME_GIF,
  474946383961: MIME_GIF,
  '3c3f786d6c20': MIME_SVG,
};

const MIME_SIGNATURES_MAP_8 = {
  '89504e470d0a1a0a': MIME_PNG,
};

const MIME_SIGNATURES_MAP_14 = {
  '5249464600000000574542505650': MIME_WEBP,
};

export async function getMIME(blob) {
  const buf = await blob.slice(0, 16).arrayBuffer();
  const arr = new Uint8Array(buf);
  let signature = '';
  let mime;
  for (let i = 0; i < arr.length; i++) {
    if (i === 2 && signature in MIME_SIGNATURES_MAP_2) {
      mime = MIME_SIGNATURES_MAP_2[signature];
      break;
    } else if (i === 4 && signature in MIME_SIGNATURES_MAP_4) {
      mime = MIME_SIGNATURES_MAP_4[signature];
      break;
    } else if (i === 6 && signature in MIME_SIGNATURES_MAP_6) {
      mime = MIME_SIGNATURES_MAP_6[signature];
      break;
    } else if (i === 8 && signature in MIME_SIGNATURES_MAP_8) {
      mime = MIME_SIGNATURES_MAP_8[signature];
      break;
    } else if (i === 14) {
      if (isWebp(signature)) {
        mime = MIME_WEBP;
      }
      break;
    }
    signature += arr[i].toString(16).padStart(2, '0');
  }
  return mime || blob.type || 'unknown';
}

function isWebp(signature) {
  const arr = [...signature];
  arr.splice(8, 8, ...Array(8).fill('0'));
  const masked = arr.join('');
  return MIME_SIGNATURES_MAP_14[masked] === MIME_WEBP;
}

export const ACCEPTED_MIME_TYPES = new Set([MIME_BMP, MIME_PNG, MIME_GIF, MIME_JPG, MIME_ICO, MIME_WEBP, MIME_SVG]);

export function getErrorMessage(err, fallback = 'Something went wrong') {
  const { response, message } = err;
  if (response) {
    const { detail, title } = response.data || {};
    return detail || title || message || fallback;
  }
  return message || fallback;
}

export const toFloat = (val) => {
  const norm = parseFloat(val);
  return isNaN(norm) ? 0 : norm;
};

export const toNumber = (val) => {
  const norm = parseInt(val);
  return isNaN(norm) ? 0 : norm;
};

export function isUrl(value) {
  try {
    return !!new URL(value.toString());
  } catch (e) {
    return false;
  }
}

export function isPath(value) {
  return /(\/\w+)+\/?([?#].*)?/i.test(value);
}

export function apiId2Id(s) {
  if (!s) {
    return s;
  }
  return s.match(/\d+/)[0];
}

/**
 * @param {*|string|undefined} err
 * @return {Array<string>}
 */
export function extractErrorMessages(err) {
  if (err instanceof ApiError || err?.response) {
    const { violations, detail } = err?.response?.data || {};
    if (violations?.length) {
      return violations.map(({ message }) => message);
    } else {
      const { status, statusText } = err?.response || {};
      let message = err.message;
      if (detail) {
        return [detail];
      } else {
        if (status) {
          message += `Code: ${status}`;
        }
        if (statusText) {
          message += ` | ${statusText}`;
        }
        return [message];
      }
    }
  }
  return [err?.toString()].filter(Boolean);
}

export function checkExpiration(lastUpdate, lifetime = 3.6e6) {
  return Date.now() - (lastUpdate || 0) > lifetime;
}

export function time() {
  return ~~(Date.now() / 1000);
}

export function timePoint(delta = 0) {
  return time() + delta;
}

export function formatDuration(duration) {
  if (!duration) {
    return 'n/a';
  }
  const time = moment.duration(duration, 's');
  const parts = [];
  if (time.hours() > 0) {
    parts.push(`${time.hours()} h`);
  }
  if (time.minutes() > 0) {
    parts.push(`${time.minutes()} m`);
  }
  parts.push(`${time.seconds()} s`);
  return parts.join(' ');
}

export function simplifyNumber(x = 0) {
  if (x === 0) {
    return 0;
  }
  return x < 0 ? -1 : 1;
}

export function extractFiltersFromQuery(query = {}, prop = 'filter') {
  if (query[prop]) {
    try {
      return JSON.parse(query[prop]) || {};
    } catch (e) {
      console.error(e);
    }
  }
  return {};
}

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const buf = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(buf);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([buf], { type: mimeString });
}

export async function getImageDataUrl(file) {
  if (!(file instanceof File)) {
    return Promise.reject(new Error('Not a File'));
  }
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
}

export function lookBehind(look, regex, text) {
  const match = text.match(look);
  if (match) {
    const offset = match.index + match[0].length;
    const subText = text.substring(offset);
    const subMatch = subText.match(new RegExp(`^${regex.source}`));
    if (subMatch) {
      const res = [subMatch[0]];
      res.index = subMatch.index + offset;
      res.input = text;
      res.groups = undefined;
      return res;
    }
    return null;
  }
}

export const sortAsc = (sortingValueKey) => sortBy(sortingValueKey, 'asc', (val) => (val || '').toLowerCase());

export function isObjectsEqual(objA, objB) {
  return Object.entries(diff(objA, objB)).length === 0;
}

/**
 * Returns whether the UPC (EAN-8) is valid or not
 * @param {string} code - 12-digits code (8-digits code for EAN-8)
 * @returns {boolean}
 */
export function validateUPC(code) {
  const digits = [...code].map(Number);
  const controlDigit = digits.pop();
  const sum = digits.reduce((acc, cur, i) => {
    const pos = i + 1;
    return acc + (pos % 2 === 0 ? cur : cur * 3);
  }, 0);
  const mod = sum % 10;
  return (mod === 0 ? 0 : 10 - mod) === controlDigit;
}

/**
 * Returns whether the EAN-13 is valid or not
 * @param {string} code - 13-digits code
 * @returns {boolean}
 */
export function validateEAN13(code) {
  const digits = [...code].map(Number);
  const controlDigit = digits.pop();
  const sum = digits.reduce((acc, cur, i) => {
    const pos = i + 1;
    return acc + (pos % 2 === 0 ? cur * 3 : cur);
  }, 0);
  const mod = sum % 10;
  return (mod === 0 ? 0 : 10 - mod) === controlDigit;
}

/**
 * Returns whether the EAN-8 or EAN-13 is valid or not
 * @param {string} code - 8(13)-digits code
 * @returns {boolean}
 */
export function validateEAN(code) {
  if (!code?.length) {
    return false;
  }
  if (code.length === 8) {
    return validateUPC(code);
  }
  if (code.length === 13) {
    return validateEAN13(code);
  }
  return false;
}
