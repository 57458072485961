import Dataman from 'App/Dataman';
import { FEAT_ID_FILLING_MATERIAL, FEAT_ID_REGION } from 'Lib/enums/HardcodedIds';
import { FT_LIST, FT_RANGE } from '../../FilterTypes';
import { sortBy } from 'App/utils/Helpers';
import filterItemsByRole from '../helpers/filterItemsByRole';
import { isEmpty } from '@/components/filters/Utils';

/**
 * @param {{name: string, groupOrderIndex: number}} groupConf
 */
export async function loadFeatures(groupConf) {
  const res = await Dataman.Filter.getProductFeatures({
    re: true,
    params: {
      itemsPerPage: 300,
    },
  });
  if (res.data?.length) {
    /** @type {Map<number, {id: number, name: string}>} */
    const childFeaturesList = new Map();
    res.data.forEach(({ id, name, optionsChildFeatures }) => {
      if (!isEmpty(optionsChildFeatures)) {
        Object.values(optionsChildFeatures).forEach((featureId) => childFeaturesList.set(featureId, { id, name }));
      }
    });
    return res.data
      .reduce((acc, props) => {
        if ([FEAT_ID_REGION, FEAT_ID_FILLING_MATERIAL].includes(props.id)) {
          return acc;
        }
        const { id, name, values, categories, hasOptions } = props;
        const valuesList = Object.entries(values || {})
          .map(([id, name]) => ({ id, name }))
          .sort(sortBy('text'));
        let group = { name: 'Features', groupOrderIndex: 0, isFeature: true, ...(groupConf || {}) };
        if (childFeaturesList.has(id)) {
          const parent = childFeaturesList.get(id);
          group = {
            name: parent.name,
            groupOrderIndex: (groupConf?.groupOrderIndex || 100) + parent.id,
            isFeature: true,
          };
        }
        acc.push({
          id: `feature${id}`,
          label: name,
          type: hasOptions ? FT_LIST : FT_RANGE,
          group,
          featureID: id,
          isFeature: true,
          useStrategies: true,
          hasNotStrategy: false,
          useNASearch: true,
          categories: categories?.map((el) => el.id),
          valuesList,
        });
        return acc;
      }, [])
      .sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        } else if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
  }
  return [];
}

export async function loadFeatureListValues(id) {
  const feature = await loadFeature(id);
  if (!feature || !feature?.hasOptions) {
    return [];
  }
  const result = Object.entries(feature.values).reduce((res, [key, value], i) => {
    res[i] = {
      id: key,
      name: value,
    };
    return res;
  }, Array(feature.values.length));
  const filtered = result.filter((item) => filterItemsByRole(item, id));
  return filtered.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
}

async function loadFeature(id) {
  return Dataman.Filter.getProductFeature(id).then(
    (res) => {
      return res.data;
    },
    (err) => {
      console.error(err);
      return null;
    }
  );
}

export default {
  loadFeatures,
  loadFeatureListValues,
};
