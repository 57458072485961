import ItemMaster from '@/app/ItemMaster';
import Dataman from 'App/Dataman';
import { sortBy } from 'App/utils/Helpers';
import { Trans } from 'App/plugins/Translation';

export function getListMapper({ textProp = 'name', valueProp = 'id' } = {}) {
  return (el) => {
    const { [textProp]: name, [valueProp]: value } = el;
    return { value, text: `${value} - ${name}` };
  };
}

export function mapToList(data, textProp = 'name', valueProp = 'id') {
  return data.map((item) => ({
    text: item[textProp],
    value: item[valueProp],
  }));
}

export function filterCompanyAutocomplete(item, queryText) {
  const { name, originalName, dbas } = item;
  const rex = new RegExp(queryText, 'i');
  return [name, originalName, ...(dbas?.map((el) => el.name) || [])].some((el) => {
    rex.lastIndex = 0;
    return rex.test(el);
  });
}

export function mapCompanies(list) {
  return list.map((item) => {
    const { id, name, originalName, location, region } = item;
    let text = `${id} - ${name}`;
    const locPts = [location?.iso3166Alpha2, region?.iso3166Alpha2].filter(Boolean);
    if (locPts.length) {
      text += ` (${locPts.join('-')})`;
    }
    return { _text: text, _subtitle: originalName, ...item };
  });
}

export function mapEvents(list) {
  return list.map((item) => {
    const { id, name } = item;
    let text = `${id} - ${name}`;
    return { id: id, _text: text };
  });
}

export async function searchCompany(term) {
  return await ItemMaster.getList({
    category: 'companies',
    raw: true,
    filter: {
      complex_name: term,
      properties: ['id', 'name', 'originalName', 'dbas', 'location', 'region'],
      itemsPerPage: 100,
    },
  }).then((data) => mapCompanies(data));
}

export async function searchEvent(term) {
  return await ItemMaster.getList({
    category: 'events',
    raw: true,
    filter: {
      complex_name: term,
      properties: ['id', 'name'],
      itemsPerPage: 100,
    },
  }).then((data) => mapEvents(data));
}

export async function searchSample(term, productIds) {
  let requestData = { itemsPerPage: 300, 'product.name': term, 'product.id': [...productIds] };
  if (!term) {
    delete requestData['product.name'];
  }
  return Dataman.DB.samples
    .getList(requestData)
    .then((res) =>
      res.data
        .sort(sortBy('name', 'ASC'))
        .map((el) => ({ text: `${el.name} (${el.barcode})`, value: el.id, product: el.product ? el.product.id : null }))
    );
}

export async function statusItemsLoader() {
  return await Dataman.DB.deal_statuses
    .getList({
      useInProductOrder: true,
      'order[position]': 'ask',
      'translations.locale': Trans.currentLanguage,
      type: 1,
    })
    .then((res) => {
      return res.data['hydra:member'].map((el) => ({ name: el.name, id: el.id }));
    });
}

export async function searchStatusItems(term) {
  return await Dataman.DB.deal_statuses
    .getList({
      name: term,
      'translations.locale': Trans.currentLanguage,
      useInProductOrder: true,
      'order[position]': 'ask',
      type: 1,
    })
    .then((res) => {
      return res.data['hydra:member'].map((el) => ({ name: el.name, id: el.id }));
    });
}

export async function searchUsers(term) {
  return await Dataman.DB.users
    .getList({
      'roles.title': 'ROLE_CUSTOMER',
      name: term,
      'order[name]': 'ask',
    })
    .then((res) => {
      return res.data['hydra:member'].map((el) => ({ name: el.name, id: el.id }));
    });
}

export async function searchCustomers(term, projects = null) {
  let preparedParams = {
    complex_name: term,
    'order[name]': 'ask',
    projects: projects,
  };
  if (!projects) {
    delete preparedParams.projects;
  }
  return await Dataman.DB.customers.getList(preparedParams).then((res) => {
    return res.data['hydra:member'].map((el) => ({ name: el.name, id: el.id }));
  });
}

export async function searchProjects(term, customers = null) {
  let preparedParams = {
    name: term,
    'order[name]': 'ask',
    'customers.id': customers,
  };
  if (!customers) {
    delete preparedParams.customers;
  }
  return await Dataman.DB.projects.getList(preparedParams).then((res) => {
    return res.data['hydra:member'].map((el) => ({ name: el.name, id: el.id }));
  });
}
