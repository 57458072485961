import { apiDelete, ApiError, apiGet, apiPost, apiPostJSON, apiPut } from 'App/Dataman';
import { ASSISTANT_PATHS } from '@/app/api/assistant/paths';

/** @typedef {Object} LogItem
 * @prop {number} id
 * @prop {String} url
 * @prop {String} createdAt
 * @prop {null|boolean} success
 * @prop {String} error
 * @prop {number} wimsId
 */

/** @typedef {Object} RunAssistantResults
 * @prop {Array<String>} questions
 * @prop {Array<String>} answers
 * @prop {String} product
 */

/**
 * List of the 100 latest URLs and their status to analyse information
 * @returns {Promise<Array<LogItem>>}
 */
export function getAssistantLog() {
  return apiGet(ASSISTANT_PATHS.assistant).then(onResponse);
}

/**
 * Provide a list of URLs to process them thorough this service
 * @param urlList {Array<string>}
 * @returns {Promise<boolean>}
 * @throws ApiError
 */
export function setAssistantUrls(urlList) {
  return apiPostJSON(ASSISTANT_PATHS.assistant, urlList).then((res) => {
    if (res.status === 204) {
      return true;
    } else {
      throw new ApiError(res.statusText, res);
    }
  });
}

/**
 * Run Assistant
 * @returns {Promise<RunAssistantResults>}
 * @throws ApiError
 */
export function runAssistant() {
  return apiPost(ASSISTANT_PATHS.runAssistant).then(onResponse);
}

/**
 * Deletes an log item
 * @param id
 * @returns {Promise<boolean>}
 */
export function deleteItem(id) {
  return apiDelete(`${ASSISTANT_PATHS.assistant}/${id}`).then((res) => {
    if (res.status === 204) {
      return true;
    } else {
      throw new ApiError(res.statusText, res);
    }
  });
}

export function reloadLogItem(id) {
  return apiPut(`${ASSISTANT_PATHS.assistant}/${id}/reload`).then((res) => {
    if (res.status === 204) {
      return true;
    } else {
      throw new ApiError(res.statusText, res);
    }
  });
}

function onResponse(res) {
  const { status, data } = res;
  if (status === 200) {
    return data;
  } else {
    throw new ApiError(res.statusText, res);
  }
}

export default { getAssistantLog, setAssistantUrls, runAssistant, deleteItem, reloadLogItem };
