import store from 'App/store/vuex';
import Dataman from 'App/Dataman';
import {
  CAT_BRANDS,
  CAT_COMPANIES,
  CAT_COMPONENT_CATEGORIES,
  CAT_COMPONENT_PACKAGES,
  CAT_COMPONENTS,
  CAT_CONSUMABLE_VARIATIONS,
  CAT_CONTACTS,
  CAT_FEATURE_OPTIONS,
  CAT_FEATURES,
  CAT_FILES,
  CAT_NEWS,
  CAT_COMPANY_NEWS,
  CAT_NICE_CLASSES,
  CAT_PATENT_RESEARCH,
  CAT_PATENTS,
  CAT_PRODUCTS,
  CAT_PROOF_REQUESTS,
  CAT_SAMPLE_CONDITIONS,
  CAT_SAMPLES,
  CAT_STANDING_REPORTS,
  CAT_STANDING_REPORTS_SECTIONS,
  CAT_TRADEMARKS,
  CAT_TYPES,
  CAT_USERGROUPS,
  CAT_USERS,
  searchEntities,
  STATS_CATEGORIES_MAP,
  CAT_CPC_CLASSIFICATION,
  CAT_IPC_CLASSIFICATION,
  CAT_PATENT_CITATIONS,
  CAT_PATENT_NOTES,
  CAT_USER_FILTERS,
  CAT_PRODUCT_MARKETS,
  CAT_SIMILAR_PRODUCTS,
  CAT_EVENTS,
  CAT_ATTENDING_REQUESTS,
  CAT_EVENT_TYPES,
  CAT_EVENT_THEMES,
  CAT_RESEARCHES,
  CAT_PRODUCT_ORDERS,
  CAT_TRACKING_INFORMATION,
  CAT_BILLING_INFORMATION,
  CAT_COURIER_DELIVERY_SERVICES,
  CAT_CUSTOMERS,
  CAT_PROJECTS,
  CAT_AGENTS,
  CAT_ORDER_AGENTS,
  CAT_RECOMMENDED_PRODUCTS,
  CAT_PRODUCT_MARKET_COUNTRIES,
} from 'App/utils/Collections';
import StaffStatistics from 'Models/StaffStatistics';

const { Interface } = store.state;

class ApiCat {
  /**
   * @param {object} methods
   * @param {object} conf
   * @param {string} conf.category
   * @param {function()|object} [conf.model]
   * @param {object} [conf.models]
   */
  constructor(methods, conf) {
    const { category, model, models = {} } = conf;
    this._model = model;
    this._methods = methods || {};
    this._category = category;
    this._models = models || {};
  }

  _callMethod = (method, args, def = null) => {
    if (typeof this._methods[method] === 'function') {
      return this._methods[method](...args);
    }

    return def;
  };

  get category() {
    return this._category;
  }

  async getModel(method) {
    const model = this._models[method] || this._model || null;
    try {
      if (typeof model === 'function' && !/^class\s/.test(model.toString())) {
        return model().then((module) => module?.default || module);
      }
    } catch (e) {
      return model || null;
    }
    return model || null;
  }

  LoadAll = (...args) => this._callMethod('LoadAll', args, []);
  LoadOne = (...args) => this._callMethod('LoadOne', args, null);
  CreateOne = (...args) => this._callMethod('CreateOne', args);
  update = (...args) => this._callMethod('update', args);
  remove = (...args) => this._callMethod('remove', args);
}

const ApiCategories = {
  [CAT_PRODUCTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getProducts,
      LoadOne: Dataman.DB.getProductDetails,
      CreateOne: Dataman.DB.createProduct,
      update: Dataman.DB.updateProduct,
    },
    { category: CAT_PRODUCTS, model: () => import('Models/Product') }
  ),
  [CAT_SIMILAR_PRODUCTS]: new ApiCat(
    {
      LoadAll: ({ productId, ...filter }) => Dataman.DB.similarProducts.getSimilarProducts(productId, filter),
      LoadOne: Dataman.DB.getProductDetails,
    },
    { category: CAT_PRODUCTS, model: () => import('Models/SimilarProduct') }
  ),
  [CAT_COMPANIES]: new ApiCat(
    {
      LoadAll: Dataman.DB.getCompanies,
      LoadOne: Dataman.DB.getCompanyDetails,
      CreateOne: Dataman.DB.createCompany,
      update: Dataman.DB.updateCompany,
    },
    { category: CAT_COMPANIES, model: () => import('Models/Company') }
  ),
  [CAT_COMPONENTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getComponents,
      LoadOne: Dataman.DB.getComponentDetails,
      CreateOne: Dataman.DB.createComponent,
      update: Dataman.DB.updateComponent,
    },
    { category: CAT_COMPONENTS, model: () => import('Models/Component') }
  ),
  [CAT_CONTACTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getContacts,
      LoadOne: Dataman.DB.getContactsDetails,
      CreateOne: Dataman.DB.createContact,
      update: Dataman.DB.updateContact,
    },
    { category: CAT_CONTACTS, model: () => import('Models/Contact') }
  ),
  [CAT_TRADEMARKS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getTrademarks,
      LoadOne: Dataman.DB.getTrademarksDetails,
      CreateOne: Dataman.DB[CAT_TRADEMARKS].create,
    },
    { category: CAT_TRADEMARKS, model: () => import('Models/Trademark') }
  ),
  [CAT_BRANDS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getBrands,
      LoadOne: Dataman.DB.getBrandsDetails,
      CreateOne: Dataman.DB.createBrand,
      update: Dataman.DB.updateBrand,
    },
    { category: CAT_BRANDS, model: () => import('Models/Brand') }
  ),
  [CAT_PATENT_RESEARCH]: new ApiCat(
    {
      LoadAll: Dataman.DB.patentResearch.getList,
      LoadOne: Dataman.DB.patentResearch.getItem,
      CreateOne: Dataman.DB.patentResearch.createItem,
      update: Dataman.DB.patentResearch.updateItem,
    },
    { category: CAT_PATENT_RESEARCH, model: () => import('Models/PatentResearch') }
  ),
  [CAT_PRODUCT_ORDERS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getProductOrders,
      LoadOne: Dataman.DB.getProductOrder,
      update: Dataman.DB.updateProductOrderInformation,
      CreateOne: Dataman.DB.createProductOrder,
      remove: Dataman.DB.deleteProductOrder,
    },
    { category: CAT_PRODUCT_ORDERS, model: () => import('Models/CMSProductOrders') }
  ),
  [CAT_CUSTOMERS]: new ApiCat(
    {
      LoadAll: Dataman.DB.customers.getList,
      LoadOne: Dataman.DB.customers.getItem,
      update: Dataman.DB.customers.update,
      CreateOne: Dataman.DB.customers.create,
    },
    { category: CAT_CUSTOMERS, model: () => import('Models/CMSCustomers') }
  ),
  [CAT_PROJECTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.projects.getList,
      LoadOne: Dataman.DB.projects.getItem,
      update: Dataman.DB.projects.update,
      CreateOne: Dataman.DB.projects.create,
    },
    { category: CAT_PROJECTS, model: () => import('Models/CMSProjects') }
  ),
  [CAT_AGENTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.agents.getList,
      LoadOne: Dataman.DB.agents.getItem,
      update: Dataman.DB.agents.update,
      CreateOne: Dataman.DB.agents.create,
    },
    { category: CAT_AGENTS, model: () => import('Models/CMSAgents') }
  ),
  [CAT_RECOMMENDED_PRODUCTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.recommendedProducts.getList,
      LoadOne: Dataman.DB.recommendedProducts.getItem,
      update: Dataman.DB.recommendedProducts.update,
      CreateOne: Dataman.DB.recommendedProducts.create,
    },
    { category: CAT_RECOMMENDED_PRODUCTS, model: () => import('Models/CMSRecommendedProducts') }
  ),
  [CAT_COURIER_DELIVERY_SERVICES]: new ApiCat(
    {
      LoadAll: Dataman.DB.getCourierDeliveryServises,
      LoadOne: Dataman.DB.getCourierDeliveryServiseItem,
      update: Dataman.DB.updateCourierDeliveryServise,
      CreateOne: Dataman.DB.createCourierDeliveryServise,
    },
    { category: CAT_COURIER_DELIVERY_SERVICES, model: () => import('Models/CMSCourierDeliveryServices') }
  ),
  [CAT_TRACKING_INFORMATION]: new ApiCat(
    {
      LoadAll: Dataman.DB.getTrackingInformations,
      update: Dataman.DB.updateTrackingInformation,
      CreateOne: Dataman.DB.createTrackingInformation,
    },
    { category: CAT_TRACKING_INFORMATION, model: () => import('Models/CMSTrackingInformation') }
  ),
  [CAT_BILLING_INFORMATION]: new ApiCat(
    {
      LoadOne: Dataman.DB.getProductOrder,
      update: Dataman.DB.updateProductOrderInformation,
    },
    { category: CAT_BILLING_INFORMATION, model: () => import('Models/CMSProductOrders') }
  ),
  [CAT_ORDER_AGENTS]: new ApiCat(
    {
      LoadOne: Dataman.DB.orderAgents.getItem,
      LoadAll: Dataman.DB.orderAgents.getList,
      update: Dataman.DB.orderAgents.update,
      CreateOne: Dataman.DB.orderAgents.create,
      remove: Dataman.DB.orderAgents.remove,
    },
    { category: CAT_ORDER_AGENTS, model: () => import('Models/CMSOrderAgents') }
  ),
  [CAT_PROOF_REQUESTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getProofRequests,
      LoadOne: Dataman.DB.getProofRequestDetails,
      CreateOne: Dataman.DB.postProofRequest,
      remove: Dataman.DB.deleteProofRequest,
    },
    { category: CAT_PROOF_REQUESTS, model: () => import('Models/ProofRequest') }
  ),
  [CAT_PATENTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getPatents,
      LoadOne: Dataman.DB.getPatentDetails,
      CreateOne: Dataman.DB.createPatent,
    },
    { category: CAT_PATENTS, model: () => import('Models/Patent') }
  ),
  [CAT_COMPANY_NEWS]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_COMPANY_NEWS].getList,
      LoadOne: Dataman.DB[CAT_COMPANY_NEWS].getItem,
    },
    { category: CAT_COMPANY_NEWS, model: () => import('Models/CompanyNewsItem') }
  ),
  [CAT_NEWS]: new ApiCat(
    {
      LoadAll: Dataman.DB.news.getLDList,
      LoadOne: Dataman.DB.news.getItem,
      CreateOne: Dataman.DB.news.create,
      update: Dataman.DB.news.update,
    },
    { category: CAT_NEWS, model: () => import('Models/NewsItem') }
  ),
  [CAT_STANDING_REPORTS]: new ApiCat(
    {
      CreateOne: Dataman.DB.postStandingReport,
      LoadOne: Dataman.DB.getStandingReport,
      LoadAll: Dataman.DB.getStandingReports,
      update: Dataman.DB.patchStandingReport,
    },
    { category: CAT_STANDING_REPORTS, model: () => import('Models/StandingReport') }
  ),
  reportSections: new ApiCat(
    {
      CreateOne: Dataman.DB.postStandingReportSection,
    },
    { category: CAT_STANDING_REPORTS_SECTIONS, model: () => import('Models/StandingReportSection') }
  ),
  [CAT_FILES]: new ApiCat(
    {
      LoadAll: Dataman.DB.files.getList,
      LoadOne: Dataman.DB.files.getItem,
      CreateOne: Dataman.DB.files.create,
      update: Dataman.DB.files.update,
      remove: Dataman.DB.files.remove,
    },
    { category: CAT_FILES, model: () => import('Models/File') }
  ),
  [CAT_SAMPLES]: new ApiCat(
    {
      LoadAll: Dataman.DB.samples.getLDList,
      LoadOne: Dataman.DB.samples.getItem,
      CreateOne: Dataman.DB.samples.create,
      update: Dataman.DB.samples.update,
    },
    { category: CAT_SAMPLES, model: () => import('Models/Sample') }
  ),
  [CAT_SAMPLE_CONDITIONS]: new ApiCat(
    {
      LoadAll: Dataman.DB.sampleConditions.getLDList,
      LoadOne: Dataman.DB.sampleConditions.getItem,
      CreateOne: Dataman.DB.sampleConditions.create,
      update: Dataman.DB.sampleConditions.update,
    },
    { category: CAT_SAMPLE_CONDITIONS, model: () => import('Models/SampleCondition') }
  ),
  [CAT_CPC_CLASSIFICATION]: new ApiCat(
    {
      LoadAll: Dataman.DB.cpcClassifications.getList,
    },
    { category: CAT_CPC_CLASSIFICATION, model: () => import('Models/PatentClassCpc') }
  ),
  [CAT_IPC_CLASSIFICATION]: new ApiCat(
    {
      LoadAll: Dataman.DB.ipcClassifications.getList,
    },
    { category: CAT_IPC_CLASSIFICATION, model: () => import('Models/PatentClassIpc') }
  ),
  [CAT_PATENT_NOTES]: new ApiCat(
    {
      LoadAll: Dataman.DB.getPatentInternalNotes,
      CreateOne: Dataman.DB.createPatentInternalNote,
      remove: Dataman.DB.deletePatentInternalNote,
      update: Dataman.DB.updatePatentInternalNote,
    },
    {
      category: CAT_PATENT_NOTES,
      model: () => import('Models/PatentNote'),
    }
  ),
  ...Object.fromEntries(
    Object.entries(STATS_CATEGORIES_MAP).map(([cat, data]) => {
      return [
        data.id,
        new ApiCat(
          {
            LoadAll: Dataman.DB.staffStats[cat].getGroupedList,
          },
          { category: data.id, model: StaffStatistics.Overviews.modelsMap[data.id] }
        ),
      ];
    })
  ),

  ...Object.fromEntries(
    Object.entries(STATS_CATEGORIES_MAP).map(([cat, data]) => {
      return [
        data.itemCat,
        new ApiCat(
          {
            LoadAll: Dataman.DB.staffStatsItem[cat].getList,
            LoadOne: Dataman.DB.staffStatsItem[cat].getItem,
          },
          { category: data.itemCat, model: StaffStatistics.modelsMap[data.itemCat] }
        ),
      ];
    })
  ),

  [CAT_CONSUMABLE_VARIATIONS]: new ApiCat(
    {
      LoadAll: Dataman.DB.productConsumableVariations.getList,
      LoadOne: Dataman.DB.productConsumableVariations.getItem,
      CreateOne: Dataman.DB.productConsumableVariations.create,
      update: Dataman.DB.productConsumableVariations.update,
    },
    { category: CAT_CONSUMABLE_VARIATIONS, model: () => import('Models/ConsumableVariations') }
  ),
  [CAT_TYPES]: new ApiCat(
    {
      LoadAll: Dataman.DB.types.getList,
      LoadOne: Dataman.DB.types.getItem,
      CreateOne: Dataman.DB.types.create,
      update: Dataman.DB.types.update,
    },
    { category: CAT_TYPES, model: () => import('Models/Type') }
  ),
  [CAT_COMPONENT_PACKAGES]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_COMPONENT_PACKAGES].getList,
    },
    { category: CAT_COMPONENT_PACKAGES }
  ),
  [CAT_COMPONENT_CATEGORIES]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_COMPONENT_CATEGORIES].getList,
    },
    { category: CAT_COMPONENT_CATEGORIES }
  ),
  [CAT_USERGROUPS]: new ApiCat(
    {
      LoadAll: Dataman.DB.usergroups.getList,
    },
    { category: CAT_USERGROUPS, model: () => import('Models/Role') }
  ),
  [CAT_USERS]: new ApiCat(
    {
      LoadAll: Dataman.DB.users.getList,
      LoadOne: Dataman.DB.users.getItem,
      CreateOne: Dataman.DB.users.create,
      update: Dataman.DB.users.update,
    },
    { category: CAT_USERS, model: () => import('Models/CMSUser') }
  ),
  [CAT_RESEARCHES]: new ApiCat(
    {
      LoadAll: Dataman.DB.researches.getList,
      LoadOne: Dataman.DB.researches.getItem,
      update: Dataman.DB.researches.update,
    },
    { category: CAT_RESEARCHES, model: () => import('Models/CMSResearch') }
  ),
  [CAT_FEATURES]: new ApiCat(
    {
      LoadAll: Dataman.DB.features.getList,
      LoadOne: Dataman.DB.features.getItem,
      CreateOne: Dataman.DB.features.create,
      update: Dataman.DB.features.update,
    },
    { category: CAT_FEATURES, model: () => import('Models/Feature') }
  ),
  [CAT_FEATURE_OPTIONS]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_FEATURE_OPTIONS].getList,
      LoadOne: Dataman.DB[CAT_FEATURE_OPTIONS].getItem,
      CreateOne: Dataman.DB[CAT_FEATURE_OPTIONS].create,
      update: Dataman.DB[CAT_FEATURE_OPTIONS].update,
    },
    { category: CAT_FEATURE_OPTIONS, model: () => import('Models/FeatureOption') }
  ),
  [CAT_NICE_CLASSES]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_NICE_CLASSES].getList,
    },
    { category: CAT_NICE_CLASSES }
  ),
  [CAT_PATENT_CITATIONS]: new ApiCat(
    {
      LoadAll: Dataman.DB[CAT_PATENT_CITATIONS].getList,
    },
    { category: CAT_PATENT_CITATIONS, model: () => import('Models/PatentCitation') }
  ),
  [CAT_USER_FILTERS]: new ApiCat(
    {
      LoadAll: Dataman.User.getFilters,
    },
    {
      category: CAT_USER_FILTERS,
      model: () => import('Models/UserFilter'),
    }
  ),
  [CAT_PRODUCT_MARKETS]: new ApiCat(
    {
      LoadAll: Dataman.DB.getProductMarketCountries,
    },
    {
      category: CAT_PRODUCT_MARKETS,
      model: () => import('Models/ProductMarketCountry'),
    }
  ),
  [CAT_PRODUCT_MARKET_COUNTRIES]: new ApiCat(
    {
      LoadAll: Dataman.DB.productMarketCountries.getList,
    },
    {
      category: CAT_PRODUCT_MARKET_COUNTRIES,
      model: () => import('Models/ProductMarketCountry'),
    }
  ),
  [CAT_EVENTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.events.get,
      CreateOne: Dataman.DB.events.create,
      update: Dataman.DB.events.update,
      LoadOne: Dataman.DB.events.getItem,
    },
    {
      category: CAT_EVENTS,
      model: () => import('Models/Event'),
    }
  ),
  [CAT_ATTENDING_REQUESTS]: new ApiCat(
    {
      LoadAll: Dataman.DB.attendingRequests.get,
    },
    {
      category: CAT_ATTENDING_REQUESTS,
      model: () => import('Models/AttendingRequest'),
    }
  ),
  [CAT_EVENT_TYPES]: new ApiCat(
    {
      LoadAll: Dataman.DB.eventTypes.get,
      LoadOne: Dataman.DB.eventTypes.getItem,
      update: Dataman.DB.eventTypes.update,
      CreateOne: Dataman.DB.eventTypes.create,
      remove: Dataman.DB.eventTypes.remove,
    },
    {
      category: CAT_EVENT_TYPES,
      model: () => import('Models/EventType'),
    }
  ),
  [CAT_EVENT_THEMES]: new ApiCat(
    {
      LoadAll: Dataman.DB.eventThemes.get,
      LoadOne: Dataman.DB.eventThemes.getItem,
      update: Dataman.DB.eventThemes.update,
      CreateOne: Dataman.DB.eventThemes.create,
      remove: Dataman.DB.eventThemes.delete,
    },
    {
      category: CAT_EVENT_THEMES,
      model: () => import('Models/EventTheme'),
    }
  ),
};

function getConnector(category) {
  const connector = ApiCategories[category];
  if (connector === void 0) {
    throw new Error(`Can not find category "${category}"`);
  }
  return connector;
}

/**
 * @param {object} options
 * @param {string} options.category
 * @param {object} [options.filter]
 * @param {boolean} [options.raw=false]
 * @return Promise<{ items: Array<object>, total: number, category: string }>
 */
const loadItems = async (options, headers) => {
  let { category, filter, raw, ...rest } = options || {};
  const connector = getConnector(category);
  const ItemModel = await connector.getModel('LoadAll');
  return connector.LoadAll(filter, rest, headers).then((response) => {
    let items;
    let total = false;
    if (!!response.data && !!response.data['hydra:member']) {
      items = response.data['hydra:member'];
      total = response.data['hydra:totalItems'];
    } else {
      items = response.data;
    }
    if (!raw && ItemModel) {
      items = (items || []).map((props) => new ItemModel(props));
    }
    if ([CAT_PRODUCT_MARKETS, CAT_EVENT_TYPES, CAT_EVENT_THEMES].includes(category)) {
      total = items.length;
    }
    return { items, total, category };
  });
};

const ItemMaster = {
  Dataman,
  loadItems,
  getList: async (...args) => {
    const data = await loadItems(...args);
    Interface.setTotal(data.category, data.total);
    return data.items;
  },
  getListItem: async (options) => {
    const { category, id, raw, headers } = options || {};
    const connector = getConnector(category);
    const ItemModel = await connector.getModel('LoadOne');
    const response = await connector.LoadOne(id, headers);
    if (raw || !ItemModel) {
      return response.data;
    } else {
      return new ItemModel(response.data);
    }
  },
  updateItem: async (options) => {
    const { category, data, id, raw } = options || {};
    const connector = getConnector(category);
    const ItemModel = await connector.getModel('LoadOne');
    const response = await connector.update(id, data);
    if (raw || !ItemModel) {
      return response.data;
    } else {
      return new ItemModel(response.data);
    }
  },
  createItem: async (options) => {
    const { category, data, raw } = options || {};
    const connector = getConnector(category);
    const ItemModel = await connector.getModel('LoadOne');
    const response = await connector.CreateOne(data);
    if (raw || !ItemModel) {
      return response.data;
    } else {
      return new ItemModel(response.data);
    }
  },
  removeItem: async (options) => {
    const { category, id, raw } = options || {};
    const connector = getConnector(category);
    const ItemModel = await connector.getModel('LoadOne');
    const response = await connector.remove(id);
    if (raw || !ItemModel) {
      return response.data;
    } else {
      return new ItemModel(response.data);
    }
  },
  search: async (params) => {
    const { query, entities, ...rest } = params || {};
    if (!query || !query.length) {
      return {};
    }
    let ents = entities;
    if (!entities || !entities.length) {
      ents = [...searchEntities];
    }

    return Dataman.DB.search({ q: query, entities: ents, ...rest }).then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return {};
    });
  },
  count: async (options) => {
    const { category, filter } = options || {};
    const connector = getConnector(category);
    return connector.LoadAll({ itemsPerPage: 0, ...(filter || {}) }).then((response) => {
      return response.data['hydra:totalItems'] || 0;
    });
  },
  loadVideos: async (id) => {
    const connector = getConnector(CAT_FILES);
    const response = await connector.LoadAll({ fileGroup: 17, products: id });
    return response.data['hydra:member'].map((file) => file.downloadUrl);
  },
  loadVideo: async (downloadUrl) => {
    const id = downloadUrl.split('/').pop();
    const response = await Dataman.DB.getProductFile(id, 'blob');
    const blobUrl = URL.createObjectURL(new Blob([response.data]));

    return blobUrl;
  },
};

export default ItemMaster;
