import { FILTERS_MAP } from './config';
import Filter from 'Components/filters/Filter';
import { CAT_PRODUCTS, SUB_PRODUCTS_VAPING_DEV, CAT_BRANDS, CAT_COMPANIES } from 'App/utils/Collections';

/**
 * @class FilterBox
 */
export default class FilterBox {
  /**
   * @param {VueRouter} router
   * @param {Store} store
   * @param {string=production} [mode]
   */
  constructor(router, store, mode = 'production') {
    this._router = router;
    this._store = store;
    this._mode = mode;
    /** @type {Map<string, Filter>} */
    this.filters = new Map();
    this.isActiveUserFilter = false;
  }

  /**
   * Creates filter for category with predefined config
   * @param {string} category
   * @param {string} [key]
   * @param {boolean} [force]
   * @returns {Filter}
   */
  addModuleFilter = (category, key, force = false) => {
    const conf = FILTERS_MAP[category];
    if (!conf) {
      throw new Error(`Can not find filter configuration for category "${category}".`);
    }
    const _key = key || category;
    return this.createFilter(_key, conf, force);
  };

  /**
   * @param {string} key
   * @param {object} conf
   * @param {boolean} force
   * @returns {Filter}
   */
  createFilter = (key, conf, force = false) => {
    if (force || !this.filters.has(key)) {
      const filter = new Filter(this._store, conf, this._mode);
      this.filters.set(key, filter);
    }
    return this.filters.get(key);
  };

  /**
   * @param {string} key
   * @return {Filter}
   */
  getFilter = (key) => {
    return this.filters.get(key);
  };

  /**
   * @param {string} filterKey
   * @param {string} itemKey
   * @return {FilterItem|null}
   */
  getFilterItem = (filterKey, itemKey) => {
    const filter = this.getFilter(filterKey);
    return filter?.getItem(itemKey) || null;
  };

  resetFilters = (key) => {
    const filter = this.filters.get(key);
    if (filter) {
      filter.deactivateAll();
      filter.active = false;
    }
  };

  navWithFilter = async (category, key, filter, to) => {
    await this.setFilters(category, key, filter);
    if (to) {
      return this._router.push(to);
    }
  };

  navWithUserFilter = async (userFilter, to) => {
    this.isActiveUserFilter = true;
    await this.setUserFilter(userFilter);
    if (to) {
      this._router.push(to);
    }
  };

  setFilters = async (category, key, filters, searchString = '', strict = false) => {
    const filter = this.addModuleFilter(category, key);
    if (filter) {
      await filter.init();
      await filter.applyFilter(filters, searchString, strict);
      filter.active = true;
    }
    return filter;
  };

  setUserFilter = async (userFilter) => {
    const { __filtersKey } = userFilter.conditions;
    let key = __filtersKey;
    if (!key) {
      key =
        userFilter.category === CAT_PRODUCTS ||
        userFilter.category === CAT_BRANDS ||
        userFilter.category === CAT_COMPANIES
          ? `${userFilter.category}::${SUB_PRODUCTS_VAPING_DEV}`
          : userFilter.category;
    }
    const filter = this.addModuleFilter(userFilter.category, key);
    if (filter) {
      await filter.init();
      await filter.applyUserFilter(userFilter);
      filter.active = true;
    }
  };

  saveStoredFilters = (key, filter) => {
    return this._store.commit('setFilter', { key, data: filter });
  };

  updateFilters = (key, filter) => {
    return this._store.dispatch('updateFilter', { key, data: filter });
  };

  clearFilters = (key) => {
    this._store.dispatch('clearFilter', key);
  };

  getFilters = (key) => {
    const { [key]: filters } = this._store.state.filters || {};
    return {
      __searchbar__: '',
      __pager__: { itemsPerPage: 30, page: 1 },
      ...(filters || {}),
    };
  };
}
