import { apiGet, apiPost, apiPatch, apiDelete, ApiError } from '../../Dataman';
import { DEALS_PATHS } from './deals_paths';
import { createRequestBodyAdapter } from './adapters';
import Backend from 'App/Dataman-Backend';
import { isEmpty } from '@/components/filters/Utils';

const { apiUrlSuffix } = Backend.config;

export const dealsAPiAdapter = {
  getProductOrders: (params) => apiGet(DEALS_PATHS.deals + apiUrlSuffix, { params }),
  getProductOrder: (id, params) => apiGet(`${DEALS_PATHS.deals}/${id}`, { params }),
  createProductOrder: (data) => {
    let preparedData = { ...createRequestBodyAdapter(data) };
    delete preparedData.trackingInformation;
    return apiPost(DEALS_PATHS.deals, { data: preparedData });
  },
  updateProductOrderInformation: (id, data) => {
    let preparedData = { ...data };
    preparedData = updateDealAdapter(preparedData);
    delete preparedData.trackingInformation;
    return apiPatch(`${DEALS_PATHS.deals}/${id}`, { data: preparedData });
  },
  deleteProductOrder: (id) => apiDelete(`${DEALS_PATHS.deals}/${id}`),
  generateOrdersReport: async (data) => {
    const res = await apiPost(DEALS_PATHS.report, { data, responseType: 'blob' });
    if (res.status === 201) {
      const { data, headers } = res;
      return {
        file: data,
        contentType: headers['content-type'],
        size: headers['content-length'],
        fileName: (headers['content-disposition'] || '').match(/(?<=filename=").*(?=")/)?.[0],
      };
    } else {
      throw new ApiError('Error', res);
    }
  },
};

function updateDealAdapter(requestBody) {
  const { billing_informations, dealItems, ...rest } = requestBody;
  const data = { ...rest };
  if (!isEmpty(dealItems)) {
    data.dealItems = dealItems.map((dealItem) => {
      const res = {
        name: dealItem.item.name,
        quantity: dealItem.quantity,
      };
      if (dealItem.item.wimsId) {
        res.wimsId = dealItem.item.wimsId;
      }
      return res;
    });
  }
  if (!isEmpty(billing_informations)) {
    data.billing_informations = billing_informations;
  }
  return data;
}
